const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6965393', space_js: '//h5fpb1.szcgsoft.cn/site/to_nw_nl/g/source/wg.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6965394', space_js: '//h5fpb1.szcgsoft.cn/source/u/p_oxo/source/m/hxl.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6965395', space_js: '//h5fpb1.szcgsoft.cn/source/vq/py/p/static/niyn.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6965396', space_js: '//h5fpb1.szcgsoft.cn/site/wrq/static/z/production/qoj/z/resource/q.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_banner: BANNER,
    Home_native: NATIVE_1,
    Home_native_center1: NATIVE_2,
    Detail_inter: INTER,
    Detail_banner: BANNER,
    Detail_native: NATIVE_1,
    Detail_native_center1: NATIVE_2,
    Result_inter: INTER,
    Result_banner: BANNER,
    Result_native: NATIVE_1,
    Result_native_center1: NATIVE_2,
};
